import { useEffect } from 'react';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Card, Stack, Link, Typography, CardContent, Alert, AlertTitle } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
// hooks
import useAuth from '../../hooks/useAuth';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import { NetworkHook } from '../../hooks/networkHook';
import "../../assets/styles.css";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  paddingLeft: '0px !important',
  paddingRight: '0px !important'
}));

const SectionStyle = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '50%',
  display: 'flex',
  flexDirection: 'column',
  borderTopRightRadius: '35px',
  borderBottomRightRadius: '35px'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '50%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// Define your styles using makeStyles
const useStyles = makeStyles({
  rightBgStyle: {
    width: '100%',
    background: 'url(/static/illustrations/bg-right.svg) no-repeat scroll left center/cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  leftBgStyle: {
    background: 'url(/static/illustrations/login_bg.svg) no-repeat scroll left center/cover',
    display: 'flex',
    justifyContent: 'center'
  }
});


// ----------------------------------------------------------------------

export default function Login() {

  // context
  const { isOnline } = NetworkHook()
  const { loginLogo, setLoginLogo, getLoginLogo } = useAuth();

  // Use the useStyles hook to access the defined styles
  const classes = useStyles();

  useEffect(() => {
    const fetchMyAPI = async () => {
      const response = await getLoginLogo();
      setLoginLogo(response?.data);
      // Find the existing favicon link tag
      const favicon = document.querySelector("link[rel='icon']");
      favicon.href = response?.favicon;
    }

    fetchMyAPI();
  }, [loginLogo]);

  return (
    <RootStyle title="Login | BeyondQA">
      <MHidden width="mdDown">
        <SectionStyle className={classes.leftBgStyle}>
          <div>
            <img
              src="/static/illustrations/BeyondQA_Logo_White_Text.svg"
              style={{ margin: '3% 0 0 3%', position: 'absolute', top: 0, left: 0, width: '10%' }}
              alt="Bqa Logo"
            />
          </div>
          <Typography variant="body1" display="inline" sx={{ fontSize: '2vw', color: '#FFFFFF', marginLeft: '13%' }}>
            Welcome Back!
          </Typography>
          <Typography variant="body1" display="inline" sx={{ color: '#aea6a6', margin: '1% 0 0 13%' }}>
            To keep connected with us,
          </Typography>
          <Typography variant="body1" display="inline" sx={{ color: '#aea6a6', margin: '0 0 0 13%' }}>
            please login with your personal info.
          </Typography>
        </SectionStyle>
      </MHidden>

      <Box className={classes.rightBgStyle}>
        <ContentStyle>
          <div style={{ position: 'absolute', bottom: 0, right: 0, paddingRight: '2%', paddingBottom: '2%' }}>
            <Typography variant="body1" display="inline" sx={{ fontSize: '1.5vw', color: 'gray', fontWeight: 300, letterSpacing: '1px' }}>
              Great to see you again
            </Typography>
            <Typography variant="body1" display="inline" sx={{ fontSize: '1.5vw', color: '#fb5d2e' }}>
              !!
            </Typography>
          </div>
        </ContentStyle>
      </Box>



      <Card sx={{ position: 'fixed', top: '50%', left: '55%', transform: 'translate(-50%, -50%)', zIndex: 9999, width: '30%', backgroundColor: 'rgb(255,255,255)', boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.1)' }}>
        {
          (!isOnline) && <Stack direction='row' justifyContent='center'>
            <Alert sx={{ width: '90%', my: 1, py: 1 }} severity="error">
              <AlertTitle>No Network</AlertTitle>
              Network Disconnected. Please Make sure you are connected to internet.
            </Alert>
          </Stack>
        }

        <CardContent sx={{ padding: '10% 8% 12% 8% !important' }}>

          {/* <CardContent sx={{paddingTop: '10%', paddingBottom : '12% !important'}}> */}
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <div>
                <Typography variant="body1" display="inline" sx={{ fontSize: '2vw', color: '#212668' }}>
                  Login
                </Typography>
                <span style={{ margin: '0 2px' }} />
              </div>
            </Box>
          </Stack>
          <LoginForm />

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" to="/">
                Get started
              </Link>
            </Typography>
          </MHidden>
        </CardContent>
      </Card>

    </RootStyle>
  );
}
